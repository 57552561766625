<template>
  <div>
    <div v-for="(item, index) in list" :key="index" class="flex items-center mb-2" @click="select(item)">
      <div
        :id="`prices-${index}`"
        :name="item"
        class="w-5 h-5 mr-2 border rounded inline-flex justify-center items-center"
        :class="{
          'border-gray-300': !selectedValues.includes(item),
          'border-green-default ring ring-green-200': selectedValues.includes(item),
        }"
      >
        <CheckmarkIcon v-if="selectedValues.includes(item)" class="w-3 h-3 text-green-default" />
      </div>

      <label :for="`prices-${index}`" class="text-gray-700 text-sm">${{ item }}</label>
    </div>
  </div>
</template>

<script>
import CheckmarkIcon from "@/assets/svg/checkmark.svg?inline";

export default {
  components: {
    CheckmarkIcon,
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    selectedValues: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    select(item) {
      this.$emit("select", item);
    },
  },
};
</script>
