// eslint-disable-next-line import/no-cycle
import { api } from "@/8base";
import { CREATE_WIN_MUTATION, DELETE_WIN_MUTATION, WINS_LIST_QUERY } from "@/graphql/wins";

const initialState = {
  wins: [],
};

const moduleGetters = {
  wins: (state) => state.wins.map((win) => ({ ...win, game: { ...win.game } })),
};

const mutations = {
  STORE_WINS(state, wins) {
    state.wins = wins;
  },
  UPDATE_WINS(state, win) {
    state.wins.push(win);
  },
  DELETE_WIN(state, id) {
    state.wins = state.wins.filter((item) => item.id !== id);
  },
};

const actions = {
  async fetchWins({ commit, dispatch, getters }) {
    dispatch("setLoading", true);

    if (!getters.wins.length) {
      const { data } = await api.request(WINS_LIST_QUERY);

      if (data && data.winsList) {
        commit("STORE_WINS", data.winsList.items);
      }
    }

    dispatch("setLoading", false);
  },
  async createWin({ getters, commit }, { gameId, prizeId }) {
    const { data, errors } = await api.mutation(CREATE_WIN_MUTATION, {
      gameId,
      prizeId,
      userId: getters.user.id,
    });

    if (!errors && data.winCreate) {
      commit("UPDATE_WINS", data.winCreate);
    }

    return { data, errors };
  },
  async deleteWin({ commit }, id) {
    const { data, errors } = await api.mutation(DELETE_WIN_MUTATION, { id });

    if (!errors && data.winDelete) {
      commit("DELETE_WIN", id);
    }

    return { data, errors };
  },
};

export default {
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations,
};
