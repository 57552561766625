<template>
  <div :id="id">
    <label id="listbox-label" class="block text-md text-gray-700 mb-2 font-medium">
      {{ title }}
    </label>
    <div v-click-outside="hidePopup" class="mt-1 relative">
      <button
        type="button"
        class="
          select-button
          relative
          w-full
          bg-white
          border border-gray-300
          rounded-md
          shadow-sm
          pl-3
          pr-10
          py-2
          text-left
          cursor-default
          focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500
          sm:text-sm
        "
        aria-haspopup="listbox"
        aria-expanded="true"
        aria-labelledby="listbox-label"
        @click.stop="showPopup"
      >
        <span
          class="flex items-center truncate text-sm"
          :class="selected === -1 ? 'text-gray-400' : 'text-gray-700 font-medium'"
          >{{ selected !== -1 ? selectedValue : placeholder }}</span
        >
        <span class="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon class="h-5 w-5 text-gray-400 text-sx fill-current" />
        </span>
      </button>

      <transition
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div v-if="visible && list.length" class="absolute z-40 pb-8 w-full">
          <ul
            class="
              options
              mt-1
              w-full
              bg-white
              shadow-lg
              rounded-md
              py-1
              text-base
              ring-1 ring-black ring-opacity-5
              max-h-56
              overflow-auto
            "
          >
            <li
              v-for="(item, index) in list"
              :id="index"
              :key="index"
              class="block text-gray-400 cursor-default select-none relative py-2 pl-3 pr-9"
              role="option"
              @click="change(index)"
            >
              <div
                class="flex items-center truncate text-sm"
                :class="index === selected ? 'font-semibold text-green-default' : 'font-normal text-gray-700'"
              >
                {{ item.name }}
              </div>

              <span
                v-if="index === selected"
                class="text-green-default absolute inset-y-0 right-0 flex items-center pr-4"
              >
                <CheckIcon class="h-5 w-5 fill-current" />
              </span>
            </li>
          </ul>
        </div>
      </transition>
    </div>

    <FormInput class="mb-6" :name="label" :label="label" type="hidden" :value="selectedValue" :rules="'required'" />
  </div>
</template>

<script>
import CheckIcon from "heroicons/solid/check.svg?inline";
import SelectorIcon from "heroicons/solid/selector.svg?inline";
import FormInput from "./FormInput.vue";

export default {
  components: {
    FormInput,
    CheckIcon,
    SelectorIcon,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
      required: false,
      default: -1,
    },
    title: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  computed: {
    id() {
      return `select-${this.title
        .toLowerCase()
        .replace(/ /g, "-")
        .replace(/[^\w-]+/g, "")}`;
    },
    selectedValue() {
      if (this.selected === -1) return null;

      return this.list[this.selected].name;
    },
  },
  methods: {
    hidePopup() {
      this.visible = false;
    },
    showPopup() {
      this.visible = true;
    },
    change(index) {
      this.$emit("change", index);
      this.hidePopup();
    },
  },
};
</script>
