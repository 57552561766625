<template>
  <div class="sharing">
    <a
      class="sharing__link sharing--facebook"
      :href="`https://facebook.com/sharer/sharer.php?u=${url}`"
      target="_blank"
      rel="noopener"
    >
      <FacebookIcon class="sharing__svg" />
    </a>

    <a
      class="sharing__link sharing--twitter"
      :href="`https://twitter.com/intent/tweet/?text=${subject}&url=${url}`"
      target="_blank"
      rel="noopener"
    >
      <TwitterIcon class="sharing__svg" />
    </a>

    <a
      class="sharing__link sharing--email"
      :href="`mailto:?subject=${subject}&body=${url}`"
      target="_blank"
      rel="noopener"
      aria-label=""
    >
      <EmailIcon class="sharing__svg" />
    </a>

    <a
      class="sharing__link sharing--linkedin"
      :href="`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${subject}&summary=${subject}&source=${url}`"
      target="_blank"
      rel="noopener"
    >
      <LinkedinIcon class="sharing__svg" />
    </a>

    <a
      class="sharing__link sharing--whatsapp"
      :href="`whatsapp://send?text=${subject} ${url}`"
      target="_blank"
      rel="noopener"
    >
      <WhatsappIcon class="sharing__svg" />
    </a>

    <a
      class="sharing__link sharing--telegram"
      :href="`https://telegram.me/share/url?text=${subject}&url=${url}`"
      target="_blank"
      rel="noopener"
    >
      <TelegramIcon class="sharing__svg" />
    </a>
  </div>
</template>

<script>
import EmailIcon from "@/assets/svg/social/email.svg?inline";
import FacebookIcon from "@/assets/svg/social/facebook.svg?inline";
import LinkedinIcon from "@/assets/svg/social/linkedin.svg?inline";
import TelegramIcon from "@/assets/svg/social/telegram.svg?inline";
import TwitterIcon from "@/assets/svg/social/twitter.svg?inline";
import WhatsappIcon from "@/assets/svg/social/whatsapp.svg?inline";

export default {
  components: { EmailIcon, FacebookIcon, LinkedinIcon, TelegramIcon, TwitterIcon, WhatsappIcon },
  props: {
    url: {
      type: String,
      required: true,
    },
    subject: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.sharing {
  &__link {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 2.5rem;
    height: 2.5rem;
    margin: 0 0.25rem;

    border-radius: 0.25rem;

    &.sharing--twitter {
      background-color: #55acee;
    }

    &.sharing--facebook {
      background-color: #3b5998;
    }

    &.sharing--linkedin {
      background-color: #0077b5;
    }

    &.sharing--email {
      background-color: #777;
    }

    &.sharing--whatsapp {
      background-color: #25d366;
    }

    &.sharing--telegram {
      background-color: #54a9eb;
    }
  }

  &__svg {
    color: #fff;
    width: 1.25rem;
    height: 1.25rem;
  }
}
</style>
