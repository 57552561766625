const initialState = {
  authenticated: !!localStorage.getItem("id_token"),
  idToken: localStorage.getItem("id_token"),
  refreshToken: localStorage.getItem("refresh_token"),
};

const moduleGetters = {
  authenticated: (state) => state.authenticated,
  idToken: (state) => state.idToken,
  refreshToken: (state) => state.refreshToken,
};

const mutations = {
  UPDATE_AUTHENTICATED(state, value) {
    state.authenticated = value;
  },
  UPDATE_ID_TOKEN(state, value) {
    state.idToken = value;
  },
  UPDATE_REFRESH_TOKEN(state, value) {
    state.refreshToken = value;
  },
};

const actions = {
  authenticate({ commit }, auth) {
    commit("UPDATE_AUTHENTICATED", true);
    commit("UPDATE_ID_TOKEN", auth.idToken);

    localStorage.setItem("id_token", auth.idToken);
    localStorage.setItem("refresh_token", auth.refreshToken);
  },

  logout({ commit, dispatch }) {
    commit("UPDATE_AUTHENTICATED", false);
    commit("UPDATE_ID_TOKEN", false);
    commit("UPDATE_REFRESH_TOKEN", false);

    localStorage.removeItem("id_token");
    localStorage.removeItem("refresh_token");

    dispatch("closeAllPopups");
    dispatch("closeMenu");
  },
};

export default {
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations,
};
