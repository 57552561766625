import Vue from "vue";
import VueRouter from "vue-router";

// eslint-disable-next-line import/no-cycle
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: () => import("@/pages/auth/Welcome.vue"),
    meta: { layout: "Blank" },
  },
  {
    path: "/login",
    name: "LogIn",
    component: () => import("@/pages/auth/LogIn.vue"),
    meta: { layout: "Auth" },
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: () => import("@/pages/auth/SignUp.vue"),
    meta: { layout: "Auth" },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("@/pages/auth/ResetPassword.vue"),
    meta: { layout: "Auth" },
  },
  {
    path: "/how-do-you-play",
    name: "HowDoYouPlay",
    component: () => import("@/pages/profile/HowDoYouPlay.vue"),
    meta: { layout: "Default" },
  },
  {
    path: "/change-password",
    name: "ChangePassword",
    component: () => import("@/pages/profile/ChangePassword.vue"),
    meta: { layout: "Default" },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/pages/profile/Profile.vue"),
    meta: { layout: "Default" },
  },
  {
    path: "/my-wins/grid",
    name: "MyWinsGrid",
    component: () => import("@/pages/wins/MyWinsGrid.vue"),
    meta: { layout: "Default" },
  },
  {
    path: "/my-wins/list",
    name: "MyWinsList",
    component: () => import("@/pages/wins/MyWinsList.vue"),
    meta: { layout: "Default" },
  },
  {
    path: "/games",
    name: "Games",
    component: () => import("@/pages/games/Games.vue"),
    meta: { layout: "Default" },
  },
  {
    path: "/wild-cards",
    name: "WildCards",
    component: () => import("@/pages/games/WildCards.vue"),
    meta: { layout: "Default" },
  },
  {
    path: "/recommended-games",
    name: "RecommendedGames",
    component: () => import("@/pages/games/RecommendedGames.vue"),
    meta: { layout: "Default" },
  },
  {
    path: "/game/:id",
    name: "Game",
    component: () => import("@/pages/games/Game.vue"),
    meta: { layout: "Default" },
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("@/pages/static/PrivacyPolicy.vue"),
    meta: { layout: "Static" },
  },
  {
    path: "/terms-and-conditions",
    name: "TermsConditions",
    component: () => import("@/pages/static/TermsConditions.vue"),
    meta: { layout: "Static" },
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("@/pages/PageNotFound.vue"),
    meta: { layout: "Blank" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const { authenticated } = store.getters;

  if (to.meta.layout === "Default" && !authenticated) return next({ name: "Welcome" });
  if ((to.meta.layout === "Auth" || to.name === "Welcome") && authenticated) return next({ name: "Games" });

  return next();
});

export default router;
