<template>
  <Popup id="recommendation-info-popup" @close="close">
    <h2 class="title-1 mt-4 mb-6 text-center">{{ recommendationInfoPopupData.title }}</h2>
    <p class="mb-8 text-center leading-normal">{{ recommendationInfoPopupData.description }}</p>

    <div class="text-center mt-5 pb-6">
      <button class="btn btn-green" @click="close">Close</button>
    </div>
  </Popup>
</template>

<script>
import { mapGetters } from "vuex";
import Popup from "@/components/ui/Popup.vue";

export default {
  components: { Popup },
  computed: {
    ...mapGetters(["recommendationInfoPopupData"]),
  },
  methods: {
    close() {
      this.$store.commit("UPDATE_SHOW_RECOMMENDATION_INFO_POPUP", false);
    },
  },
};
</script>
