import { render, staticRenderFns } from "./LazyLoadingAnimation.vue?vue&type=template&id=1b57e804&scoped=true&"
var script = {}
import style0 from "./LazyLoadingAnimation.vue?vue&type=style&index=0&id=1b57e804&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b57e804",
  null
  
)

export default component.exports