<template>
  <div id="share-popup" class="fixed inset-0 flex justify-center z-50">
    <div class="fixed z-10 inset-0 bg-black opacity-30 h-auto" @click="$emit('close')" />

    <div class="overflow-auto absolute inset-0">
      <div class="relative z-10 mt-16 mb-8 mx-6">
        <div class="relative bg-white rounded w-full max-w-xs p-4 mx-auto">
          <CloseIcon
            id="close-popup-btn"
            class="absolute top-0 right-0 w-6 h-6 mt-4 mr-4 text-gray-700"
            @click="$emit('close')"
          />
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/assets/svg/close.svg?inline";

export default {
  components: { CloseIcon },
};
</script>
