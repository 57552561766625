// eslint-disable-next-line import/no-cycle
import router from "@/router";

export function getRecommendedPrizeFilter(prize) {
  let recommendedPrizeFilter = {};

  if (router.currentRoute.name === "RecommendedGames") {
    if (prize === "1000-20000") {
      recommendedPrizeFilter = { AND: [{ topPrizeValue: { gte: 1000 } }, { topPrizeValue: { lte: 20000 } }] };
    } else if (prize === "20000-100000") {
      recommendedPrizeFilter = { AND: [{ topPrizeValue: { gte: 20000 } }, { topPrizeValue: { lte: 100000 } }] };
    } else if (prize === "100000-10000000") {
      recommendedPrizeFilter = { AND: [{ topPrizeValue: { gte: 100000 } }, { topPrizeValue: { lte: 10000000 } }] };
    } else if (prize === ">=10000000") {
      recommendedPrizeFilter = { AND: [{ topPrizeValue: { gte: 10000000 } }] };
    }
  }

  return recommendedPrizeFilter;
}

export function getWildcardPrizeFilter(prize) {
  let wildcardPrizeFilter = {};

  if (router.currentRoute.name === "WildCards") {
    if (prize === "1000-20000") {
      wildcardPrizeFilter = { OR: [{ topPrizeValue: { lt: 1000 } }, { topPrizeValue: { gt: 20000 } }] };
    } else if (prize === "20000-100000") {
      wildcardPrizeFilter = { OR: [{ topPrizeValue: { lt: 20000 } }, { topPrizeValue: { gt: 100000 } }] };
    } else if (prize === "100000-10000000") {
      wildcardPrizeFilter = { OR: [{ topPrizeValue: { lt: 100000 } }, { topPrizeValue: { gt: 10000000 } }] };
    } else if (prize === ">=10000000") {
      wildcardPrizeFilter = { AND: [{ topPrizeValue: { lt: 10000000 } }] };
    }
  }

  return wildcardPrizeFilter;
}
