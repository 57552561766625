import Vue from "vue";
import Vuex from "vuex";
import app from "./app";
import auth from "./auth";
// eslint-disable-next-line import/no-cycle
import profile from "./profile";
// eslint-disable-next-line import/no-cycle
import games from "./games";
// eslint-disable-next-line import/no-cycle
import game from "./game";
// eslint-disable-next-line import/no-cycle
import wins from "./wins";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    auth,
    profile,
    games,
    game,
    wins,
  },
});
