<template>
  <div>
    <ValidationProvider v-slot="{ errors }" :name="label" :rules="rules">
      <label v-if="label && type !== 'hidden'" class="block text-sm text-gray-700" :for="name">{{ label }}</label>
      <div class="relative">
        <input
          :id="name"
          :value="value"
          class="text-input mt-2"
          :placeholder="label"
          :class="{ 'mod-error': errors.length }"
          :type="formType"
          :disabled="disabled"
          @input="change"
        />
        <span v-if="type === 'password'" class="absolute right-0 top-0 mt-3 mr-3" @click="togglePassword">
          <EyeOnIcon v-if="formType === 'text'" class="w-5 h-5 text-gray-700" />
          <EyeOffIcon v-else class="w-5 h-5 text-gray-400" />
        </span>
      </div>
      <span v-if="errors[0]" class="inline-block mt-2 text-xs text-red-400">{{ errors[0] }}</span>
    </ValidationProvider>
  </div>
</template>

<script>
import EyeOnIcon from "@/assets/svg/eye-on.svg?inline";
import EyeOffIcon from "@/assets/svg/eye-off.svg?inline";

export default {
  components: { EyeOnIcon, EyeOffIcon },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
    rules: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formType: this.type,
    };
  },
  methods: {
    change(event) {
      this.$emit("change", {
        key: this.name,
        value: event.target.value,
      });
    },
    togglePassword() {
      if (this.formType === "password") {
        this.formType = "text";
      } else if (this.formType === "text") {
        this.formType = "password";
      }
    },
  },
};
</script>
