<template>
  <div class="text-center min-h-screen flex flex-col justify-center items-center py-16">
    <slot />
    <Cookies v-if="!cookiesAccepted" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Cookies from "@/components/Cookies.vue";

export default {
  components: { Cookies },
  computed: {
    ...mapGetters(["cookiesAccepted"]),
  },
};
</script>
