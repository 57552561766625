<template>
  <div class="absolute inset-0">
    <div id="mobile-menu-overlay" class="fixed inset-0 bg-black opacity-30 h-auto" @click="closeMenu" />

    <div id="mobile-menu" class="fixed top-0 bottom-0 pt-16 pl-6 overflow-auto z-10 h-full w-64 bg-white">
      <div id="close-menu-btn" class="absolute z-20 top-0 left-0 p-4 ml-3 mt-2" @click="closeMenu">
        <CloseIcon class="w-6 h-6 text-gray-600" />
      </div>

      <RouterLink to="/games" class="flex justify-start items-center my-3" @click.native="closeMenu">
        <AllGamesIcon class="w-6 h-6 text-gray-700" />
        <p class="ml-2 font-medium text-gray-700">All Games</p>
      </RouterLink>
      <RouterLink to="/recommended-games" class="flex justify-start items-center my-3" @click.native="closeMenu">
        <RecommendedIcon class="w-6 h-6 text-gray-700" />
        <p class="ml-2 font-medium text-gray-700">{{ user.username }}'s Picks</p>
      </RouterLink>
      <RouterLink to="/wild-cards" class="flex justify-start items-center my-3" @click.native="closeMenu">
        <WildCardsIcon class="w-6 h-6 text-gray-700" />
        <p class="ml-2 font-medium text-gray-700">Wild Cards</p>
      </RouterLink>

      <div class="my-8 mr-6 bg-gray-200 h-0.5" />

      <RouterLink to="/my-wins/grid" class="flex justify-start items-center my-3" @click.native="closeMenu">
        <MyWinsIcon class="w-6 h-6 text-gray-700" />
        <p class="ml-2 font-medium text-gray-700">My Wins</p>
      </RouterLink>
      <RouterLink to="/profile" class="flex justify-start items-center my-3" @click.native="closeMenu">
        <ProfileIcon class="w-6 h-6 text-gray-700" />
        <p class="ml-2 font-medium text-gray-700">Profile</p>
      </RouterLink>
      <div class="flex justify-start items-center my-3 cursor-pointer" @click="logoutAction">
        <LogOutIcon class="w-6 h-6 text-gray-700" />
        <p id="log-out-btn" class="ml-2 font-medium text-gray-700">Log out</p>
      </div>

      <RouterLink
        id="menu-profile-link"
        to="/profile"
        class="absolute z-20 bottom-0 left-0 w-64 py-4 bg-white border-t border-gray-200"
        @click.native="closeMenu"
      >
        <div v-if="user.username" class="flex justify-start items-center pl-7">
          <GradientSimpleLogoIcon class="w-8 h-8" />
          <p class="ml-3 font-semibold text-gray-700">{{ user.username }}</p>
        </div>
      </RouterLink>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CloseIcon from "@/assets/svg/close.svg?inline";

import AllGamesIcon from "@/assets/svg/nav/all-games.svg?inline";
import RecommendedIcon from "@/assets/svg/nav/recommend.svg?inline";
import WildCardsIcon from "@/assets/svg/nav/wild-cards.svg?inline";
import MyWinsIcon from "@/assets/svg/nav/my-wins.svg?inline";
import ProfileIcon from "@/assets/svg/nav/profile.svg?inline";
import LogOutIcon from "@/assets/svg/nav/log-out.svg?inline";

import GradientSimpleLogoIcon from "@/assets/svg/logo/gradient-simple-logo.svg?inline";

export default {
  components: {
    CloseIcon,
    AllGamesIcon,
    RecommendedIcon,
    WildCardsIcon,
    MyWinsIcon,
    ProfileIcon,
    LogOutIcon,
    GradientSimpleLogoIcon,
  },
  metaInfo: {
    title: "Mobile Menu",
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["logout"]),
    logoutAction() {
      this.logout();
      this.$router.push("/");
    },
    closeMenu() {
      this.$store.commit("UPDATE_SHOW_MENU", false);
    },
  },
};
</script>
