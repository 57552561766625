<template>
  <Popup id="filters-popup" @close="close">
    <div class="w-64">
      <div class="mb-6">
        <p class="text-md font-bold text-gray-800 truncate mb-4">Tickets Prices</p>
        <CheckboxesGroup :list="prices" :selected-values="selectedPrices" @select="selectGroup" />
      </div>

      <p class="text-md font-bold text-gray-800 truncate mb-4">Sort by</p>

      <div class="mb-4">
        <RadioGroup
          :id="'prizes-radio'"
          :list="prizes"
          :selected="selectedGoal"
          @change="(selected) => changeSelected(selected, 'selectedGoal')"
        />
      </div>

      <button id="apply-filters-btn" class="btn btn-green" @click="applyFilters">Apply</button>
    </div>
  </Popup>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Popup from "@/components/ui/Popup.vue";
import RadioGroup from "@/components/ui/RadioGroup.vue";
import CheckboxesGroup from "@/components/ui/CheckboxesGroup.vue";
import { tickets } from "@/constants";

export default {
  components: { Popup, RadioGroup, CheckboxesGroup },
  data() {
    return {
      selectedPrices: [],
      selectedGoal: "any",
      prizes: [
        { name: "Any Prize", value: "any" },
        { name: "Top Prize", value: "top" },
      ],

      prices: tickets.map((item) => parseInt(item.value, 10)),
    };
  },

  computed: {
    ...mapGetters(["goalSort", "priceFilter"]),
  },

  created() {
    this.selectedPrices = this.priceFilter.slice();
    this.selectedGoal = this.goalSort;
  },

  methods: {
    ...mapActions(["updateFilters"]),
    applyFilters() {
      this.updateFilters({
        priceFilter: this.selectedPrices,
        goalSort: this.selectedGoal,
      });

      this.close();
    },
    close() {
      this.$store.commit("UPDATE_SHOW_FILTER_POPUP", false);
    },
    apply() {
      this.$store.commit("UPDATE_SHOW_FILTER_POPUP", false);
    },
    changeSelected(selected, prop) {
      this[prop] = selected;
    },
    selectGroup(item) {
      if (this.selectedPrices.includes(item)) {
        this.selectedPrices = this.selectedPrices.filter((price) => price !== item);
      } else {
        this.selectedPrices.push(item);
      }
    },
  },
};
</script>
