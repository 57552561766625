<template>
  <Popup @close="close">
    <div v-if="loadingSharing" class="mt-8 mb-16"><LazyLoadingAnimation /></div>
    <ValidationObserver v-else-if="!shared" ref="observer" v-slot="{ handleSubmit, valid }">
      <h1 class="title-1 my-4 text-center">Share a Win!</h1>

      <p class="mb-6 text-gray-700 text-sm italic">Sharing your wins helps us make better recommendation</p>
      <form @submit.prevent="handleSubmit(share)">
        <div class="mb-6">
          <SelectInput
            title="What game did you play?"
            placeholder="Pick a game"
            :list="games"
            :selected="selectedGameIndex"
            label="Game"
            @change="(selected) => changeSelected(selected, 'selectedGameIndex')"
          />
        </div>

        <LazyLoadingAnimation v-if="loadingPrizes" class="mb-12" />

        <div v-if="prizes.length && !loadingPrizes" class="mb-8">
          <SelectInput
            title="Which prize did you win?"
            placeholder="Pick a prize"
            :list="prizes"
            :selected="selectedPrizeIndex"
            label="Prize"
            @change="(selected) => changeSelected(selected, 'selectedPrizeIndex')"
          />
        </div>
        <div
          v-else-if="selectedGame && !prizes.length && !loadingPrizes"
          class="text-md text-gray-700 mb-7 font-medium"
        >
          No available prizes...
        </div>

        <div class="text-center mt-5 pb-6">
          <button
            id="add-and-share-btn"
            class="btn btn-green"
            :class="valid && selectedPrize ? 'btn-green' : 'btn-disabled'"
          >
            Add & Share
          </button>
        </div>
      </form>
    </ValidationObserver>
    <div v-else>
      <h1 class="title-1 my-4 text-center">Congrats!</h1>

      <p class="mb-8 text-gray-700 text-center">
        Thank you so much for sharing that you won {{ selectedPrizeName }} by playing {{ selectedGameName }}!
      </p>

      <SharingButtons class="mb-10" :url="url" :subject="sharingSubject" />

      <div class="text-center mt-5 pb-6">
        <button id="share-another-win-btn" class="btn btn-green" @click="shareAnotherWin">Share Another Win</button>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Popup from "@/components/ui/Popup.vue";
import SelectInput from "@/components/ui/SelectInput.vue";
import { baseURL } from "@/constants";
import LazyLoadingAnimation from "@/components/ui/LazyLoadingAnimation.vue";
import SharingButtons from "@/components/ui/SharingButtons.vue";

export default {
  components: { SharingButtons, LazyLoadingAnimation, Popup, SelectInput },
  data() {
    return {
      selectedGameIndex: -1,
      selectedPrizeIndex: -1,
      shared: false,
      availablePrizes: [],
      loadingPrizes: false,
      loadingSharing: false,
      url: baseURL,
    };
  },
  computed: {
    ...mapGetters(["gameNames", "preSelectedGame", "preSelectedPrize"]),
    games() {
      return this.gameNames;
    },
    prizes() {
      return this.availablePrizes.map((item) => ({
        name: Number.isNaN(parseInt(item.value, 10)) ? `${item.value}` : this.$options.filters.formatMoney(item.value),
        id: item.id,
      }));
    },
    selectedGame() {
      if (this.selectedGameIndex === -1) return null;
      return this.games[this.selectedGameIndex];
    },
    selectedGameName() {
      return this.selectedGame ? this.selectedGame.name : "";
    },
    selectedGameId() {
      return this.selectedGame ? this.selectedGame.id : "";
    },
    selectedPrize() {
      if (this.selectedPrizeIndex === -1) return null;
      return this.availablePrizes[this.selectedPrizeIndex];
    },
    selectedPrizeName() {
      return this.selectedPrize ? this.$options.filters.formatMoney(this.selectedPrize.value) : "";
    },
    selectedPrizeId() {
      return this.selectedPrize ? this.selectedPrize.id : "";
    },
    sharingSubject() {
      return `I win ${this.selectedPrizeName} by playing ${this.selectedGameName}!`;
    },
  },
  watch: {
    async selectedGameIndex() {
      if (!this.selectedGameId) {
        this.availablePrizes = [];
        return;
      }

      this.loadingPrizes = true;
      this.availablePrizes = await this.fetchGamePrizes(this.selectedGameId);
      this.loadingPrizes = false;
    },
  },
  created() {
    if (this.preSelectedGame) {
      this.selectedGameIndex = this.games.findIndex((item) => item.name === this.preSelectedGame);
    }

    if (this.preSelectedPrize) {
      this.selectedPrizeIndex = this.prizes.findIndex((item) => item.name === `$ ${this.preSelectedPrize}`);
    }
  },
  methods: {
    ...mapActions(["fetchGamePrizes", "createWin"]),
    changeSelected(selected, prop) {
      this[prop] = selected;
    },
    close() {
      this.$store.commit("UPDATE_SHOW_SHARE_WIN_POPUP", false);
    },
    async share() {
      if (!this.selectedGameId || !this.selectedPrizeId) return;
      this.loadingSharing = true;

      await this.createWin({ gameId: this.selectedGameId, prizeId: this.selectedPrizeId });

      if (navigator.share) this.nativeShare();
      else this.fallbackShare();

      this.loadingSharing = false;
    },
    nativeShare() {
      try {
        navigator
          .share({ text: `${this.sharingSubject}\n${this.url}` })
          .then(() => {
            this.shared = true;
          })
          .catch((e) => {
            console.error(e);
            this.shared = true;
          });
      } catch (e) {
        console.error(e);
        this.shared = true;
      }
    },
    fallbackShare() {
      this.shared = true;
    },
    shareAnotherWin() {
      this.selectedGameIndex = -1;
      this.selectedPrizeIndex = -1;
      this.shared = false;
    },
  },
};
</script>
