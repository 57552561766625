<template>
  <div>
    <div class="h-14 border-b-2 border-gray-200">
      <div class="flex justify-between items-center px-6 max-w-md mx-auto h-full">
        <MenuIcon id="menu-icon" class="w-6 h-6 text-gray-700" @click="openMenu" />
        <RouterLink to="/games">
          <LogoIcon class="h-12" />
        </RouterLink>
        <div id="show-share-popup-btn" class="p-2 pr-0" @click="shareWin">
          <ShareWinIcon class="w-6 h-6 text-gray-700" style="margin-top: 1px" />
        </div>
      </div>
    </div>

    <div class="mb-12" :class="routeName !== 'Game' ? 'mt-4 mx-auto px-6 max-w-md' : ''">
      <slot />
    </div>

    <transition-group name="fade" duration="300">
      <ShareWinPopup v-if="showShareWinPopup" key="share-win-popup" />
      <WelcomePopup v-if="showWelcomePopup" key="welcome-popup" />
      <FilterPopup v-if="showFilterPopup" key="filter-popup" />
      <EditWinsPopup v-if="showEditWinsPopup" key="edit-wins-popup" />
      <RecommendationInfoPopup v-if="showRecommendationInfoPopup" key="edit-wins-popup" />
    </transition-group>

    <transition name="slide-menu" duration="300">
      <MobileMenu v-if="showMenu" />
    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import MenuIcon from "@/assets/svg/menu.svg?inline";
import LogoIcon from "@/assets/svg/logo/horz-logo.svg?inline";
import ShareWinIcon from "@/assets/svg/share-win.svg?inline";
import ShareWinPopup from "@/components/popups/ShareWinPopup.vue";
import WelcomePopup from "@/components/popups/WelcomePopup.vue";
import EditWinsPopup from "@/components/popups/EditWinsPopup.vue";
import MobileMenu from "@/components/MobileMenu.vue";
import FilterPopup from "@/components/popups/FilterPopup.vue";
import RecommendationInfoPopup from "@/components/popups/RecommendationInfoPopup.vue";

export default {
  components: {
    RecommendationInfoPopup,
    FilterPopup,
    MenuIcon,
    LogoIcon,
    ShareWinIcon,
    ShareWinPopup,
    WelcomePopup,
    EditWinsPopup,
    MobileMenu,
  },
  computed: {
    ...mapGetters([
      "showShareWinPopup",
      "showWelcomePopup",
      "showFilterPopup",
      "showEditWinsPopup",
      "showRecommendationInfoPopup",
      "showMenu",
    ]),
    routeName() {
      return this.$route.name;
    },
  },
  created() {
    this.fetchGameNames();
  },
  methods: {
    ...mapActions(["showSharingPopup", "fetchGameNames"]),

    openMenu() {
      this.$store.commit("UPDATE_SHOW_MENU", true);
    },
    shareWin() {
      this.showSharingPopup({});
    },
  },
};
</script>
