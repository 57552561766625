/**
 * Get all games
 */
export const GET_USER = `
  query {
    user {
      id
      username
      email
      defaultLottery
      prizePreference
      frequency
      spend
      ticket
    }
  }
`;

/* Send user password reset email */
export const UPDATE_USER_MUTATION = `
  mutation($id: ID!, $username: String!, $prizePreference: String!, $frequency: String!, $spend: String!, $ticket: String!) {
    userUpdate(data: { id: $id, username: $username, prizePreference: $prizePreference, frequency: $frequency, spend: $spend, ticket: $ticket }) {
      id
      username
      email
      defaultLottery
      prizePreference
      frequency
      spend
      ticket
    }
  }
`;
