<template>
  <div>
    <p v-if="title" class="text-md text-gray-700 mb-3 font-medium">{{ title }}</p>

    <div v-for="(item, index) in list" :key="index" class="flex items-center text-sm pb-3" @click="change(index)">
      <div
        :id="`${idPrefix}-${index}`"
        class="h-5 w-5 text-green-600 border rounded-full flex justify-center items-center"
        :class="{
          'border-gray-300': selected !== index,
          'border-green-light ring ring-green-200': selected === index,
        }"
      >
        <div v-if="selected === index" class="w-3 h-3 bg-green-default flex rounded-full" />
      </div>
      <div class="ml-2 text-gray-700">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
      required: false,
      default: -1,
    },
    title: {
      type: String,
      default: "",
    },
  },
  computed: {
    idPrefix() {
      return this.id || this.title.replaceAll(" ", "-").toLowerCase();
    },
  },
  methods: {
    change(index) {
      this.$emit("change", index);
    },
  },
};
</script>
