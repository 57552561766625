export const GAMES_LIST_QUERY = `
query($gameFilter: GameFilter, $limit: Int, $skip: Int, $sort: [GameSort!]) {
  gamesList(filter: $gameFilter, first: $limit, skip: $skip, sort: $sort) {
    items {
      id
      name
      topPrizeValue
      trend
      rating
      recommended
      price
      status
      thumbnail { downloadUrl }
      lotteryID
      rank
      rankPercentile
      rankByDenominationPercentile
      lottery {
        name
        logo { downloadUrl }
      }
    }
  }
}`;

export const GAME_NAMES_LIST_QUERY = `
query {
  gamesList {
    items {
      id
      name
    }
  }
}`;

export const GAME_PRIZES_QUERY = `
query($gamePrizeFilter: GamePrizeFilter) {
 gamePrizesList(filter: $gamePrizeFilter) {
    items {
      id
      value
    }
  }
}`;

export const GAME_QUERY = `
query($id: ID ) {
  game(id: $id) {
    id
    name
    topPrizeValue
    trend
    rating
    recommended
    price
    status
    thumbnail { downloadUrl }
    lottery {
      name
      logo { downloadUrl }
    }
    topPrizeValue
    nowTopOdds
    nowAllOdds
    startTopOdds
    startAllOdds
    status
    allOddsHistory
    topOddsHistory
    startDate
    endDate
    lastClaimDate
    lotteryID
    rank
    rankByDenominationPercentile
    prizes(filter: {topPrize: { equals: true }}) {
      items {
        nowCount
      }
    }
  }
}`;
