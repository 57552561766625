<template>
  <component :is="layout" v-if="layout">
    <RouterView />
  </component>
</template>

<script>
import Blank from "./layouts/Blank.vue";
import Auth from "./layouts/Auth.vue";
import Default from "./layouts/Default.vue";
import Static from "./layouts/Static.vue";

export default {
  components: {
    Blank,
    Auth,
    Default,
    Static,
  },
  computed: {
    layout() {
      return this.$route.meta.layout || "";
    },
  },
};
</script>

<style lang="scss">
#app {
  font-family: Lato, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
