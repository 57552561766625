import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, min, max } from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/en.json";

extend("required", { ...required, message: messages.required });
extend("email", { ...email, message: messages.email });
extend("min", { ...min, message: messages.min });
extend("max", { ...max, message: messages.max });

extend("password", {
  message: (field) =>
    `The ${field} must contain at least one upper case letter (A-Z), one lower case letter (a-z) and one number (0-9).`,
  validate: (value) => {
    const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])");
    return strongRegex.test(value);
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
