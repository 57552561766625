import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import store from "./store/index";

// Plugins
import "./plugins/meta";
import "./plugins/click-outside";
import "./plugins/vee-validate";
import "./plugins/numeral";
import "./plugins/vue-google-adsense";

// Assets
import "./assets/css/app.scss";
import "./assets/css/tailwind.scss";
import "./assets/css/animations.scss";

// Register Service Worker
import "./registerServiceWorker";

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
