import { GET_USER, UPDATE_USER_MUTATION } from "@/graphql/profile";
// eslint-disable-next-line import/no-cycle
import { api } from "@/8base";

const initialState = {
  user: JSON.parse(localStorage.getItem("user") || null),
};

const moduleGetters = {
  user(state) {
    return state.user || {};
  },
};

const mutations = {
  STORE_USER(state, user) {
    state.user = user;
  },
};

const actions = {
  async fetchUser({ commit }) {
    const { data } = await api.request(GET_USER);

    localStorage.setItem("user", JSON.stringify(data.user));
    commit("STORE_USER", data.user);
  },
  async updateUser({ commit }, userData) {
    const { data, errors } = await api.mutation(UPDATE_USER_MUTATION, userData);

    if (!errors && data.userUpdate) {
      localStorage.setItem("user", JSON.stringify(data.userUpdate));
      commit("STORE_USER", data.userUpdate);
    }

    return { data, errors };
  },
};

export default {
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations,
};
