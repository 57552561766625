// eslint-disable-next-line import/no-cycle
import store from "@/store";
/**
 * Find more information about the JS SDK's Api config
 * at https://docs.8base.com/
 */

const logoutAction = (errorType) => {
  console.info(`Logout on ${errorType}`);
  store.dispatch("logout");
  window.location.href = "/";
};

export default {
  headers: () => {
    const { idToken } = store.getters;

    if (idToken) {
      return {
        Authorization: `Bearer ${idToken}`,
      };
    }

    return {};
  },
  catchErrors: {
    TokenExpiredError: (error) => {
      console.error(error);
      logoutAction("TokenExpiredError");
    },
    default: (error) => {
      if (!error.message) return;
      console.error(error);

      if (error.message.includes("UserNotFoundError")) logoutAction("UserNotFoundError");
      if (error.message.includes("InvalidTokenError")) logoutAction("InvalidTokenError");
    },
  },
};
