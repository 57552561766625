<template>
  <Popup @close="close">
    <h2 class="title-1 mt-4 mb-2 text-center">Edit Wins</h2>

    <div class="flex items-center justify-center mb-8">
      <img class="inline-block w-8 h-8 mr-2" :src="getIcon(editingWin.icon)" alt="" />

      <p class="font-bold text-xl text-gray-700">
        {{ editingWin.prize | formatMoney }}
        <template v-if="formattedNextWinPrize">- {{ formattedNextWinPrize }}</template>
        <span class="text-sm font-semibold text-indigo-400">({{ selectedWins.length }})</span>
      </p>
    </div>

    <div v-if="!loading">
      <div
        v-for="(win, key) in selectedWins"
        :key="key"
        class="mb-4 pb-4 flex justify-between items-center border-b last:border-b-0 last:mb-0"
      >
        <div>
          <div class="text-sm x text-gray-700">{{ win.game.name }}</div>
          <span class="px-2 py-1 mr-4 text-white text-xs font-semibold bg-green-default rounded-full">{{
            win.prize.value | formatMoney
          }}</span>
        </div>
        <div class="bg-red-500 text-white rounded-full text-sm px-2 py-1" @click="deleteWinRecord(win.id)">Delete</div>
      </div>
    </div>
    <div v-else class="mb-16"><LazyLoadingAnimation /></div>
  </Popup>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Popup from "@/components/ui/Popup.vue";
import { possibleWins } from "@/constants";
import LazyLoadingAnimation from "@/components/ui/LazyLoadingAnimation.vue";

export default {
  components: { LazyLoadingAnimation, Popup },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["wins", "editingWin"]),
    nextWinPrise() {
      const currentWinIndex = possibleWins.findIndex((item) => this.editingWin.prize === item.prize);

      if (currentWinIndex <= 0) return 0;

      return possibleWins[currentWinIndex - 1].prize;
    },
    formattedNextWinPrize() {
      if (!this.nextWinPrise) return "";

      return this.$options.filters.formatMoney(
        this.nextWinPrise < 10 ? this.nextWinPrise - 0.01 : this.nextWinPrise - 1
      );
    },
    selectedWins() {
      return this.wins.filter((win) => {
        // lte to the current possible win
        // const lteCurrent = this.editingWin.prize <= win.prize.value;
        // gte editing win
        const gteToCurrentWin = win.prize.value >= this.editingWin.prize;

        // AND more then previous possible win
        const letToCurrentWin = this.nextWinPrise ? win.prize.value < this.nextWinPrise : true;

        return gteToCurrentWin && letToCurrentWin;
      });
    },
  },
  watch: {
    selectedWins() {
      // hide popup if no selected wins
      if (!this.selectedWins.length) this.close();
    },
  },
  methods: {
    ...mapActions(["deleteWin"]),
    async deleteWinRecord(id) {
      this.loading = true;
      await this.deleteWin(id);
      this.loading = false;
    },
    close() {
      this.$store.commit("UPDATE_SHOW_EDIT_WINS_POPUP", false);
    },
    getIcon(icon) {
      return require(`@/assets/svg/wins/${icon}.svg`);
    },
  },
};
</script>
