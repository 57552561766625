<template>
  <div class="px-6">
    <RouterLink to="/" class="block pt-5 mb-4">
      <ArrowBackIcon class="w-6 h-6 text-gray-700" />
    </RouterLink>

    <div class="mx-auto max-w-md">
      <div class="text-center">
        <GradientSimpleLogo class="inline-block w-24 h-24 mb-8" />
      </div>

      <slot />
    </div>

    <Cookies v-if="!cookiesAccepted" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ArrowBackIcon from "@/assets/svg/arrow-back.svg?inline";
import GradientSimpleLogo from "@/assets/svg/logo/gradient-simple-logo.svg?inline";
import Cookies from "@/components/Cookies.vue";

export default {
  components: { Cookies, ArrowBackIcon, GradientSimpleLogo },
  computed: {
    ...mapGetters(["cookiesAccepted"]),
  },
};
</script>
