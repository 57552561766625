export const WINS_LIST_QUERY = `
query MyQuery {
  winsList {
    items {
      id
      createdAt
      game {
        name
        thumbnail { downloadUrl }
        lotteryID
        lottery {
          name
          logo { downloadUrl }
        }
      }
      prize {
        value
      }
    }
  }
}`;

/* Create a new win */
export const CREATE_WIN_MUTATION = `
mutation($gameId: ID!, $prizeId: ID!, $userId: ID!) {
  winCreate(
    data: {
      game: {
        connect: { id: $gameId }
      },
      prize: {
        connect: { id: $prizeId }
      },
      user: {
        connect: { id: $userId }
      }
    }
  ) {
    id
    createdAt
    game {
      name
      thumbnail { downloadUrl }
      lottery {
        name
        logo { downloadUrl }
      }
    }
    prize {
      value
    }
  }
}`;

/* Delete win */
export const DELETE_WIN_MUTATION = `
mutation ($id: ID!) {
  winDelete(
    filter: { id: $id }
  ) {
      success
    }
}`;
