<template>
  <div class="text-left max-w-md mx-auto py-4 px-6">
    <RouterLink to="/" class="block pt-5 mb-4">
      <ArrowBackIcon class="w-6 h-6 text-gray-700" />
    </RouterLink>

    <slot />
  </div>
</template>
<script>
import ArrowBackIcon from "@/assets/svg/arrow-back.svg?inline";

export default {
  components: { ArrowBackIcon },
};
</script>
