export const baseURL = process.env.VUE_APP_BASE_URL || "http://luckywon.com/";

export const wantToWin = [
  { name: "Retire Now Money! ($10M+)", value: ">=10000000" },
  { name: "New Home Money! ($100K - $10M)", value: "100000-10000000" },
  { name: "New Car Money! ($20K - $100K)", value: "20000-100000" },
  { name: "Rent Money! ($1K - $20K)", value: "1000-20000" },
  { name: "Any Money!", value: ">0" },
];

export const frequencies = [
  { name: "1x / day", value: "1day" },
  { name: "1x / week", value: "1week" },
  { name: "2x / week", value: "2week" },
  { name: "3-6x / week", value: "3-6weeks" },
  { name: "1x / month", value: "1month" },
  { name: "2-3x / month", value: "2-3months" },
];

export const spends = [
  { name: "$1 - $5", value: "1-5" },
  { name: "$5 - $10", value: "5-10" },
  { name: "$10 - $20", value: "10-20" },
  { name: "$20 - $30", value: "20-30" },
  { name: "$30 - $50", value: "30-50" },
  { name: "$50 - $80", value: "50-80" },
  { name: "$80 - $100", value: "80-100" },
  { name: "$100+", value: "+100" },
];

export const tickets = [
  { name: "$1", value: "1" },
  { name: "$2", value: "2" },
  { name: "$3", value: "3" },
  { name: "$5", value: "5" },
  { name: "$10", value: "10" },
  { name: "$20", value: "20" },
  { name: "$30", value: "30" },
];

export const possibleWins = [
  { prize: 1, icon: "$1" },
  { prize: 2, icon: "$2" },
  { prize: 5, icon: "$5" },

  { prize: 10, icon: "$10" },
  { prize: 20, icon: "$20" },
  { prize: 50, icon: "$50" },

  { prize: 100, icon: "$100" },
  { prize: 200, icon: "$200" },
  { prize: 500, icon: "$500" },

  { prize: 1000, icon: "$1k" },
  { prize: 2000, icon: "$2k" },
  { prize: 5000, icon: "$5k" },

  { prize: 10000, icon: "$10k" },
  { prize: 20000, icon: "$20k" },
  { prize: 50000, icon: "$50k" },

  { prize: 100000, icon: "$100k" },
  { prize: 200000, icon: "$200k" },
  { prize: 500000, icon: "$500k" },

  { prize: 1000000, icon: "$1m" },
  { prize: 2000000, icon: "$2m" },
  { prize: 5000000, icon: "$5m" },
];

export const GAMES_LIMIT = 20;
