<template>
  <div id="cookies-popup" class="absolute inset-0 flex justify-center items-center">
    <div class="fixed z-10 inset-0 bg-black opacity-40 h-auto" @click="$emit('close')" />

    <div class="relative my-48 px-6 w-full z-10">
      <div class="bg-white px-6 pb-8 pt-6 text-center max-w-md mx-auto">
        <h3 class="title-1 mb-4">We use cookies</h3>
        <p class="mb-7">
          We use first and third party cookies to improve our service, personalize your advertising and remember your
          website preferences. If you continue to browse, you accept the use of cookies on our site. For more
          information visit our
          <a href="/privacy-policy#cookies" target="_blank" class="underline">Cookies Policy</a>.
        </p>
        <button id="accept-cookies-btn" class="btn btn-green" @click="setAcceptedCookies">Accept cookies</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions(["setAcceptedCookies"]),
  },
};
</script>
