<template>
  <Popup @close="close">
    <h1 class="title-1 my-4 text-center">Welcome!</h1>

    <p class="mb-6 text-gray-700 text-center">
      Thanks for signing up! Find your favorite scratcher, check our recommendations, and share your big wins!
    </p>

    <div class="text-center mt-5 pb-8">
      <button class="btn btn-green" @click="close">Done</button>
    </div>

    <div class="mb-8 bg-gray-200 h-0.5"></div>

    <p class="text-gray-700 text-center font-bold pb-4">Download LuckyWon<br />to your home screen!</p>

    <p class="text-gray-700 text-center text-sm pb-8">
      Just hit <ShareIcon class="inline-flex h-4 w-4 mx-0.5 text-gray-700" /> on IOS<br />
      or <HomeIcon class="inline-flex h-4 w-4 mx-0.5 text-gray-700" /> on Android and<br />
      pick "Add to home screen"
    </p>
  </Popup>
</template>

<script>
import Popup from "@/components/ui/Popup.vue";
import ShareIcon from "@/assets/svg/share.svg?inline";
import HomeIcon from "@/assets/svg/home.svg?inline";

export default {
  components: { Popup, ShareIcon, HomeIcon },
  methods: {
    close() {
      this.$store.commit("UPDATE_SHOW_WELCOME_POPUP", false);
    },
  },
};
</script>
