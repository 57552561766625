// eslint-disable-next-line import/no-cycle
import { api } from "@/8base";
import { GAME_NAMES_LIST_QUERY, GAME_PRIZES_QUERY, GAMES_LIST_QUERY } from "@/graphql/games";
import { GAMES_LIMIT } from "@/constants";
// eslint-disable-next-line import/no-cycle
import router from "@/router";
// eslint-disable-next-line import/no-cycle
import { getRecommendedPrizeFilter, getWildcardPrizeFilter } from "@/utils/gameFilters";

const initialState = {
  games: [],
  gameNames: [],
  allGamesFetched: false,
  goalSort: 0,
  priceFilter: [],
};

const moduleGetters = {
  games: (state) =>
    state.games.map((item) => ({
      ...item,
      price: item.price,
      topPrize: item.topPrizeValue,
    })),
  gameNames: (state) => state.gameNames,
  allGamesFetched: (state) => state.allGamesFetched,
  goalSort: (state) => state.goalSort,
  priceFilter: (state) => state.priceFilter.sort((a, b) => a - b),
};

const mutations = {
  STORE_GAMES(state, games) {
    state.games = games;
  },
  STORE_GAME_NAMES(state, gameNames) {
    state.gameNames = gameNames;
  },
  STORE_ALL_GAMES_FETCHED(state, allGamesFetched) {
    state.allGamesFetched = allGamesFetched;
  },
  UPDATE_GOAL_SORT(state, goalSort) {
    state.goalSort = goalSort;
  },
  UPDATE_PRICE_FILTER(state, priceFilter) {
    state.priceFilter = priceFilter;
  },
};

const actions = {
  async fetchGames({ commit, dispatch, getters }, { skip }) {
    if (skip && getters.allGamesFetched) return;
    if (!skip) {
      dispatch("setLoading", true);
      commit("STORE_ALL_GAMES_FETCHED", false);
      commit("STORE_GAMES", []);
    }

    const filterByStatuses = ["Active", "Retried"];

    // Home page filters (based on filter menu): by price & topPrizeValue
    // /recommended-games page filter (based on user preferences): should equals be to price and topPrizeValue
    // /wild-cards page filter (based on user preferences): should not be equals to user price and topPrizeValue
    const { data } = await api.request(GAMES_LIST_QUERY, {
      gameFilter: {
        price: {
          in: getters.priceFilter.length ? getters.priceFilter : undefined,
          // for /recommended-games page
          equals:
            router.currentRoute.name === "RecommendedGames" ? Number.parseInt(getters.user.ticket, 10) : undefined,
          // for /wild-cards page
          not_equals: router.currentRoute.name === "WildCards" ? Number.parseInt(getters.user.ticket, 10) : undefined,
        },
        // for /recommended-games page
        ...getRecommendedPrizeFilter(getters.user.prizePreference),
        // for /wild-cards page
        ...getWildcardPrizeFilter(getters.user.prizePreference),
        status: { in: filterByStatuses },
      },
      limit: GAMES_LIMIT,
      skip,
      sort: [
        // First we get "Active" games and then "Retried" (retried should be always at the bottom)
        { status: "ASC" },
        // Then we sort by CGCR (default: All prize) or CGCO (Top prize)
        getters.goalSort === 0 ? { currentROI: "DESC" } : { nowAllOdds: "ASC" },
      ],
    });

    const games = data && data.gamesList ? data.gamesList.items : [];
    commit("STORE_GAMES", [...getters.games, ...games]);

    // Do not re-fetch if fetched less than expected LIMIT
    if (skip && games.length < GAMES_LIMIT) commit("STORE_ALL_GAMES_FETCHED", true);

    if (!skip) dispatch("setLoading", false);
  },
  async fetchGameNames({ commit, getters }) {
    if (!getters.gameNames.length) {
      const { data } = await api.request(GAME_NAMES_LIST_QUERY);

      if (data && data.gamesList) {
        commit("STORE_GAME_NAMES", data.gamesList.items);
      }
    }
  },
  async fetchGamePrizes(store, gameId) {
    const { data } = await api.request(GAME_PRIZES_QUERY, {
      gamePrizeFilter: {
        game: {
          id: {
            equals: gameId,
          },
        },
      },
    });

    return data && data.gamePrizesList ? data.gamePrizesList.items : [];
  },
  updateFilters({ commit, dispatch }, { goalSort, priceFilter }) {
    commit("UPDATE_GOAL_SORT", goalSort);
    commit("UPDATE_PRICE_FILTER", priceFilter);
    dispatch("fetchGames", { skip: 0 });
  },
  resetFilters({ dispatch }) {
    dispatch("updateFilters", { goalSort: 0, priceFilter: [] });
  },
};

export default {
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations,
};
