import Vue from "vue";
import Adsense from "vue-google-adsense/dist/Adsense.min";
import InArticleAdsense from "vue-google-adsense/dist/InArticleAdsense.min";
import InFeedAdsense from "vue-google-adsense/dist/InFeedAdsense.min";

Vue.use(require("vue-script2"));

Vue.use(Adsense);
Vue.use(InArticleAdsense);
Vue.use(InFeedAdsense);
