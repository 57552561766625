const initialState = {
  loading: false,
  showShareWinPopup: false,
  showWelcomePopup: false,
  showFilterPopup: false,
  showEditWinsPopup: false,
  showRecommendationInfoPopup: false,
  recommendationInfoPopupData: {},
  showMenu: false,
  preSelectedGame: "",
  preSelectedPrize: "",
  // for edit wins popup
  editingWin: null,
  cookiesAccepted: localStorage.getItem("cookiesAccepted") === "true",
};

const moduleGetters = {
  loading: (state) => state.loading,
  showShareWinPopup: (state) => state.showShareWinPopup,
  showWelcomePopup: (state) => state.showWelcomePopup,
  showFilterPopup: (state) => state.showFilterPopup,
  showEditWinsPopup: (state) => state.showEditWinsPopup,
  showRecommendationInfoPopup: (state) => state.showRecommendationInfoPopup,
  recommendationInfoPopupData: (state) => state.recommendationInfoPopupData,
  showMenu: (state) => state.showMenu,
  preSelectedGame: (state) => state.preSelectedGame,
  preSelectedPrize: (state) => state.preSelectedPrize,
  editingWin: (state) => state.editingWin,
  cookiesAccepted: (state) => state.cookiesAccepted,
};

const mutations = {
  STORE_LOADING(state, loading) {
    state.loading = loading;
  },
  UPDATE_SHOW_SHARE_WIN_POPUP(state, showShareWinPopup) {
    state.showShareWinPopup = showShareWinPopup;
    state.showMenu = false;
  },
  UPDATE_SHOW_WELCOME_POPUP(state, showWelcomePopup) {
    state.showWelcomePopup = showWelcomePopup;
    state.showMenu = false;
  },
  UPDATE_SHOW_FILTER_POPUP(state, showFilter) {
    state.showFilterPopup = showFilter;
    state.showMenu = false;
  },
  UPDATE_SHOW_EDIT_WINS_POPUP(state, showEditWins) {
    state.showEditWinsPopup = showEditWins;
    state.showMenu = false;
  },
  UPDATE_SHOW_RECOMMENDATION_INFO_POPUP(state, showRecommendationInfoPopup) {
    state.showRecommendationInfoPopup = showRecommendationInfoPopup;
    state.showMenu = false;
  },
  UPDATE_RECOMMENDATION_INFO_POPUP_DATA(state, data) {
    state.recommendationInfoPopupData = data;
  },
  UPDATE_SHOW_MENU(state, showMenu) {
    state.showMenu = showMenu;
  },
  UPDATE_EDITING_WIN(state, editingWin) {
    state.editingWin = editingWin;
  },
  UPDATE_PRESELECTED_GAME(state, preSelectedGame) {
    state.preSelectedGame = preSelectedGame;
  },
  UPDATE_PRESELECTED_PRIZE(state, preSelectedPrize) {
    state.preSelectedPrize = preSelectedPrize;
  },
  STORE_COOKIES_ACCEPTED(state, cookiesAccepted) {
    state.cookiesAccepted = cookiesAccepted;
  },
};

const actions = {
  setLoading({ commit }, loading) {
    commit("STORE_LOADING", loading);
  },
  closeAllPopups({ commit }) {
    commit("UPDATE_SHOW_SHARE_WIN_POPUP", false);
    commit("UPDATE_SHOW_WELCOME_POPUP", false);
    commit("UPDATE_SHOW_FILTER_POPUP", false);
    commit("UPDATE_SHOW_EDIT_WINS_POPUP", false);
    commit("UPDATE_SHOW_RECOMMENDATION_INFO_POPUP", false);
  },
  closeMenu({ commit }) {
    commit("UPDATE_SHOW_MENU", false);
  },
  showSharingPopup({ commit }, { preSelectedGame, preSelectedPrize }) {
    commit("UPDATE_PRESELECTED_GAME", preSelectedGame || null);
    commit("UPDATE_PRESELECTED_PRIZE", preSelectedPrize || null);
    commit("UPDATE_SHOW_SHARE_WIN_POPUP", true);
  },
  showEditWinsPopup({ commit }, win) {
    if (win.count === 0) return;

    commit("UPDATE_EDITING_WIN", win || null);
    commit("UPDATE_SHOW_EDIT_WINS_POPUP", true);
  },
  showRecommendationInfoPopup({ commit }, data) {
    commit("UPDATE_RECOMMENDATION_INFO_POPUP_DATA", data);
    commit("UPDATE_SHOW_RECOMMENDATION_INFO_POPUP", true);
  },
  setAcceptedCookies({ commit }) {
    commit("STORE_COOKIES_ACCEPTED", "true");
    localStorage.setItem("cookiesAccepted", "true");
  },
};

export default {
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations,
};
