// eslint-disable-next-line import/no-cycle
import { api } from "@/8base";
import { GAME_QUERY } from "@/graphql/games";

const initialState = {
  game: null,
};

const moduleGetters = {
  game: (state) =>
    state.game
      ? {
          ...state.game,
          price: state.game.price,
          topPrize: state.game.topPrizeValue,
        }
      : null,
};

const mutations = {
  STORE_GAME(state, game) {
    state.game = game;
  },
};

const actions = {
  async fetchGame({ commit, dispatch }, id) {
    dispatch("setLoading", true);

    commit("STORE_GAME", null);
    const { data } = await api.request(GAME_QUERY, { id });

    if (data && data.game) {
      commit("STORE_GAME", data.game);
    }

    dispatch("setLoading", false);
  },
};

export default {
  state: initialState,
  getters: moduleGetters,
  actions,
  mutations,
};
